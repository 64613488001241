import { render, staticRenderFns } from "./SearchResults.vue?vue&type=template&id=69d1bcaa&"
import script from "./SearchResults.vue?vue&type=script&lang=js&"
export * from "./SearchResults.vue?vue&type=script&lang=js&"
import style0 from "./SearchResults.vue?vue&type=style&index=0&id=69d1bcaa&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports