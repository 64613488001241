var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticStyle:{"margin-top":"1em"}},[_c('v-card-title',[_vm._v("Custom feature")]),_c('v-card-text',_vm._l((_vm.filters),function(f,i){return _c('v-row',{key:'row' + i},[_c('v-col',{attrs:{"cols":"3"}},[(i == 0)?_c('v-select',{attrs:{"label":"Feature type","items":_vm.queryTypes},model:{value:(_vm.selectedQueryType),callback:function ($$v) {_vm.selectedQueryType=$$v},expression:"selectedQueryType"}}):(i == 1)?_c('v-select',{attrs:{"label":"Condition","items":['OR', 'AND']},model:{value:(_vm.method),callback:function ($$v) {_vm.method=$$v},expression:"method"}}):_vm._e()],1),_c('v-col',[_c('v-combobox',{staticClass:"code",attrs:{"label":"OSM key","search-input":f.parameter,"items":_vm.$store.state.osmKeys},on:{"update:searchInput":function($event){return _vm.$set(f, "parameter", $event)},"update:search-input":function($event){return _vm.$set(f, "parameter", $event)},"input":_vm.getValues},scopedSlots:_vm._u([(f.parameter != '' && f.parameter != null)?{key:"append",fn:function(){return [_c('a',{staticClass:"super",attrs:{"href":'https://wiki.openstreetmap.org/wiki/Key:' + f.parameter,"target":"_blank"}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-open-in-new")])],1)]},proxy:true}:null],null,true)})],1),_c('v-col',[_c('v-select',{attrs:{"label":"","items":[
            '=',
            '!=',
            '>',
            '<',
            '>=',
            '<=',
            'starts with',
            'ends with',
            'contains',
            'does not contain',
            'is null',
            'is not null',
          ]},model:{value:(f.comparison),callback:function ($$v) {_vm.$set(f, "comparison", $$v)},expression:"f.comparison"}})],1),_c('v-col',[_c('v-combobox',{staticClass:"code",attrs:{"label":"OSM value","search-input":f.value,"items":_vm.$store.state.selectedKeyValues,"disabled":f.comparison == 'is null' || f.comparison == 'is not null'},on:{"update:searchInput":function($event){return _vm.$set(f, "value", $event)},"update:search-input":function($event){return _vm.$set(f, "value", $event)}}})],1)],1)}),1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":_vm.addFilter}},[_vm._v("Add condition")]),_c('v-btn',{staticStyle:{"margin-left":"auto"},attrs:{"color":"primary","text":"","disabled":_vm.filters[0].parameter == ''},on:{"click":_vm.addCustom}},[_vm._v("Add custom feature")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }