var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',[_c('v-card',{staticStyle:{"min-height":"100%"},attrs:{"color":_vm.accepting ? '#ddd' : '#fff'},on:{"drop":_vm.onDrop,"dragover":_vm.onDragOver,"dragleave":_vm.onDragLeave}},[_c('v-card-title',[_vm._v("Selected features")]),_c('v-card-text',[(_vm.$store.state.selected.length > 0)?_c('v-col',_vm._l((_vm.$store.state.selected),function(query,i){return _c('FeatureView',{key:query.name + query.type,attrs:{"query":query,"index":i}})}),1):_c('v-col',[_c('v-alert',{attrs:{"type":"info"}},[_vm._v(" Drag and drop feature presets here to add them to the selection. ")])],1)],1)],1)],1),_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v("Feature presets")]),_c('v-card-text',[_c('div',_vm._l((_vm.$store.state.presets),function(query){return _c('v-chip',{key:query.name + query.type,staticStyle:{"margin":"0.25em"},attrs:{"color":query.type == 'point'
                ? '#8BC34A'
                : query.type == 'line'
                ? '#46d4db'
                : query.type == 'polygon'
                ? '#FFC107'
                : '#BEBEBE',"draggable":""},on:{"dragstart":function($event){return _vm.startDrag($event, query)},"click":function($event){return _vm.addFeature(query)}}},[_vm._v(_vm._s(query.name))])}),1),(_vm.$store.state.customPresets.length > 0)?_c('div',[_c('span',{staticClass:"custom-header"},[_vm._v("Custom presets")]),_vm._l((_vm.$store.state.customPresets),function(query){return _c('v-chip',{key:query.id,staticStyle:{"margin":"0.25em"},attrs:{"color":query.type == 'point'
                ? '#8BC34A'
                : query.type == 'line'
                ? '#46d4db'
                : query.type == 'polygon'
                ? '#FFC107'
                : '#BEBEBE',"draggable":"","close":""},on:{"dragstart":function($event){return _vm.startDrag($event, query)},"click":function($event){return _vm.addFeature(query)},"click:close":function($event){_vm.deleteDialog = query}}},[_vm._v(_vm._s(query.name))])})],2):_vm._e(),_c('v-dialog',{attrs:{"value":_vm.deleteDialog,"width":"auto"}},[_c('v-card',[_c('v-card-title',[_vm._v("Delete custom preset")]),_c('v-card-text',[_vm._v(" Are you sure you want to delete the custom preset \""+_vm._s(_vm.deleteDialog.name)+"\" from your account? ")]),_c('v-card-actions',{staticStyle:{"padding-bottom":"1em","margin-top":"-0.5em"}},[_c('v-btn',{attrs:{"color":"red"},on:{"click":function($event){_vm.removePreset(_vm.deleteDialog);
                  _vm.deleteDialog = false;}}},[_vm._v("Delete")]),_c('v-btn',{staticStyle:{"margin-right":"0em","margin-left":"auto"},attrs:{"color":"primary"},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("Keep preset")])],1)],1)],1)],1)],1),_c('FeatureCustom')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }